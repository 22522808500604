import styled from 'styled-components';
import { TooltipPrimitive } from '@atlaskit/tooltip';
import { Button } from 'react-bootstrap';

interface Props {
  maingtin: boolean;
}

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;

  .elements {
    padding: 0;
  }
  .super-app-theme--header {
    background: #8850bf !important;
    color: #fff;
  }

  .btn-plu-baixo {
    color: #8850bf;
    background-color: transparent;
    border-color: #8850bf;

    &:hover {
      background-color: #8850bf;
      color: #fff;
    }
  }

  .input-container {
    position: relative;
    align-items: center;
    .min-button {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      bottom: 0;
      right: 0;
      height: 2.3rem;
      width: 40px;
      background: #8850bf;
      border-radius: 0px 4px 4px 0px;
      &:hover {
        background: #57069e;
      }
      &:focus {
        background: #57069e;
      }
    }
    .min-button-loading {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      bottom: 0;
      right: 0;
      height: 37px;
      width: 40px;
      background: #8850bf;
      border-radius: 0px 4px 4px 0px;
      cursor: not-allowed
      &:hover {
        background: #57069e;
      }
      &:focus {
        background: #57069e;
      }
    }

  .input-icon {
    position: relative;

    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      padding: 10px;
      border-radius: 3px;
      height: 100%;
      width: 35px;
      font-size: 1rem;

      &:hover {
        cursor: pointer;
      }
    }

    .icon-left {
      left: 0;
      top: 0;
    }

    .icon-right {
      right: 0;
      top: 0;
    }

    .field-error {
      border-radius: 4px;
      border-bottom: 2px solid #cf1919;
      transition: 0.5s;
    }
  }
  .btnNewDataGrid {
    float: right;
    background-color: transparent;
    border: none;
    border-radius: 5px;
    transition: 0.4s;
    color: #424242;
    &:hover {
      background-color: #f4f5f7;
      color: #000;
    }
    &:focus {
      outline: 0;
    }
  }

  .btn-action {
    margin-left: 1rem;
  }
`;

export const ListItemWithoutOptions = styled.li<Props>`
  padding: 10px 5px;
  border: 1px solid #bdbdbd;
  border-radius: 8px;
  display: flex;
  align-items: center;
  width: 100%;
  background-color: #8850bf;
  color: #fff;
  position: relative;

  .icon {
    margin-right: 5px;

    &:hover {
      cursor: pointer;
    }
  }
`;

export const ListItem = styled.li<Props>`
  padding: 10px 5px;
  border: 1px solid #bdbdbd;
  border-radius: 8px;
  display: flex;
  align-items: center;
  width: 100%;
  background-color: ${(props) => (props.maingtin ? '#8850BF' : 'none')};
  color: ${(props) => (props.maingtin ? '#fff' : 'none')};
  position: relative;

  .icon {
    margin-right: 5px;

    &:hover {
      cursor: pointer;
    }
  }

  .options {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 0;
    background-color: #57069e;
    overflow: hidden;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border-radius: 8px;
    color: white;
    transition: 0.3s;
    font-size: 1.2rem;

    &:hover {
      cursor: pointer;
    }
  }
  &:hover .options {
    width: 30%;
  }
`;

export const StyledForm = styled.form`
  padding: 20px;
  border-radius: 5px;
  .inlineField {
    vertical-align: middle;
    margin: 5px 10px 5px 0;
  }
`;

export const ButtonRow = styled.button`
  border: none;
  background-color: transparent;
  color: #3d3d3d;
  outline: none;

  &:nth-child(2) {
    margin-left: 10px;
  }
  &:hover {
    background-color: none !important;
    color: #000;
    transition: 0.4s;
    text-decoration: underline;
  }
  &:focus {
    outline: none;
  }
`;

export const TooltipBreakline = styled(TooltipPrimitive)`
  white-space: pre-wrap;
  color: white;
  background: rgb(23, 43, 77);
  padding: 1rem;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.42857;
  text-decoration-skip-ink: auto;
`;

export const AlertBox = styled.div`
  position: relative;
  width: auto;
  transition: 0.5s;
  > * {
    border-radius: 4px;
    position: relative;
    border-bottom: 2px solid #cf1919;
    transition: 0.5s;
  }
  p {
    position: absolute;
    right: 0;
    border: none;
    color: #cf1919;
    transition: 0.5s;
  }
`;

export const InputIconContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  .icon-right {
    right: 0;
    top: 0;
  }
  .icon-left {
    left: 0;
    top: 0;
  }
`;

export const Icon = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  /* border-radius: 3px; */
  height: 100%;
  height: 100%;

  width: 35px;
  font-size: 1rem;
  border: none;
  padding: 10px;

  &:hover {
    cursor: pointer;
  }
  &:active,
  &:focus {
    outline: none;
    border: none;
    box-shadow: none !important;
  }
  &:disabled {
    cursor: not-allowed;
  }
`;

export const GroupItem = styled.div`
  display: grid;
  width: 100%;
  height: 60px;
  margin-top: 5px;

  grid-template-columns: auto 2fr;
  grid-template-rows: 1fr;
  white-space: nowrap;
  gap: 5px;
  z-index: 0;
  justify-content: start;
  align-items: center;
  label[data-disabled] {
    z-index: 0;
  }
  label[data-checked] {
    background-color: #8850bf !important;
    color: #ffffff;
    z-index: 0;
  }
`;

import { createRef } from 'react';

import { FormAssociado } from './Associado';
import { Select } from './Select';

export interface FormProdutoLoja {
  flg_fora_linha: {
    value: boolean;
    isInvalid: boolean;
    isRequired: boolean;
  };
  cod_ncm: {
    value: string;
    isInvalid: boolean;
    isRequired: boolean;
    ref: any;
  };
  cod_trib_entrada: {
    value: Select;
    isInvalid: boolean;
    isRequired: boolean;
  };
  cod_tributacao: {
    value: Select;
    isInvalid: boolean;
    isRequired: boolean;
  };
  tipo_associado: {
    value: number | undefined;
    isInvalid: boolean;
    isRequired: boolean;
  };
  tecla_balanca: {
    value: string;
    isInvalid: boolean;
    isRequired: boolean;
  };
  cod_receita: {
    value: Select;
    isInvalid: boolean;
    isRequired: boolean;
  };
  cod_tara: {
    value: Select;
    isInvalid: boolean;
    isRequired: boolean;
  };
  // cod_vasilhame: {
  //   value: number | string | undefined;
  //   isInvalid: boolean;
  //   isRequired: boolean;
  // };
  qtd_etiqueta: {
    value: number;
    isInvalid: boolean;
    isRequired: boolean;
  };
  flg_vale_gas: {
    value: boolean;
    isInvalid: boolean;
    isRequired: boolean;
  };
  flg_lib_fiscal: {
    value: boolean;
    isInvalid: boolean;
    isRequired: boolean;
  };
  num_lote_rast: {
    value: string;
    isInvalid: boolean;
    isRequired: boolean;
  };
  qtd_lote_rast: {
    value: number | undefined;
    isInvalid: boolean;
    isRequired: boolean;
  };
  cod_agreg_rast: {
    value: number | undefined;
    isInvalid: boolean;
    isRequired: boolean;
  };
  dta_fabricacao_rast: {
    value: string | undefined;
    isInvalid: boolean;
    isRequired: boolean;
  };

  des_trib_entrada: {
    value: string | undefined;
    isInvalid: boolean;
    isRequired: boolean;
  };
  des_tributacao: {
    value: string | undefined;
    isInvalid: boolean;
    isRequired: boolean;
  };

  cod_vasilhame: {
    value: string;
    isInvalid: boolean;
    isRequired: boolean;
    ref: any;
  };

  Associados: {
    associados: FormAssociado[];
    isInvalid: boolean;
    isRequired: boolean;
  };
  flg_veda_cred: {
    value: boolean;
    isInvalid: boolean;
    isRequired: boolean;
  };
  flg_veda_cred_lim: {
    value: boolean;
    isInvalid: boolean;
    isRequired: boolean;
  };
  per_veda_cred_lim: {
    value: string;
    isInvalid: boolean;
    isRequired: boolean;
  };
  flg_atualiza_custo_decomp: {
    value: boolean;
    isInvalid: boolean;
    isRequired: boolean;
  };
}

export const formProdutoLojaBlank = {
  flg_fora_linha: {
    value: false,
    isInvalid: false,
    isRequired: false,
  },
  cod_ncm: {
    // value: { label: undefined, value: undefined },
    // isInvalid: true,
    // isRequired: true,
    value: '',
    isInvalid: true,
    isRequired: true,
    ref: createRef<any>(),
  },
  cod_trib_entrada: {
    value: { label: undefined, value: undefined },
    isInvalid: false,
    isRequired: false,
  },
  cod_tributacao: {
    value: { label: undefined, value: undefined },
    isInvalid: false,
    isRequired: false,
  },
  tipo_associado: {
    value: 0,
    isInvalid: false,
    isRequired: false,
  },
  tecla_balanca: {
    value: '',
    isInvalid: false,
    isRequired: false,
  },
  cod_receita: {
    value: { label: undefined, value: undefined },
    isInvalid: false,
    isRequired: false,
  },
  cod_tara: {
    value: { label: undefined, value: undefined },
    isInvalid: false,
    isRequired: false,
  },
  cod_vasilhame: {
    value: '',
    isInvalid: true,
    isRequired: false,
    ref: createRef<any>(),
  },
  qtd_etiqueta: {
    value: 1,
    isInvalid: false,
    isRequired: true,
  },
  flg_vale_gas: {
    value: false,
    isInvalid: false,
    isRequired: false,
  },
  flg_lib_fiscal: {
    value: false,
    isInvalid: false,
    isRequired: false,
  },
  num_lote_rast: {
    value: '',
    isInvalid: false,
    isRequired: false,
    isInicializado: false,
  },
  qtd_lote_rast: {
    value: undefined,
    isInvalid: false,
    isRequired: false,
    isInicializado: false,
  },
  cod_agreg_rast: {
    value: undefined,
    isInvalid: false,
    isRequired: false,
    isInicializado: false,
  },
  dta_fabricacao_rast: {
    value: undefined,
    isInvalid: false,
    isRequired: false,
    isInicializado: false,
  },
  des_trib_entrada: {
    value: undefined,
    isInvalid: false,
    isRequired: false,
  },
  des_tributacao: {
    value: undefined,
    isInvalid: false,
    isRequired: false,
  },
  Associados: {
    associados: [],
    isInvalid: false,
    isRequired: false,
  },
  flg_veda_cred: {
    value: false,
    isInvalid: false,
    isRequired: false,
  },
  flg_veda_cred_lim: {
    value: false,
    isInvalid: false,
    isRequired: false,
  },
  per_veda_cred_lim: {
    value: '0,00',
    isInvalid: false,
    isRequired: false,
  },
  flg_atualiza_custo_decomp: {
    value: false,
    isInvalid: false,
    isRequired: false,
  },
};

import * as yup from 'yup';

export const schemaForm = yup
  .object({
    des_produto: yup.string().min(1).max(100).required(),
    des_reduzida: yup.string().min(1).max(50).required(),
    cod_departamento: yup
      .object({ label: yup.string(), value: yup.number() })
      .required(),
  })
  .required();

import { FormEmbalagem } from './protocols/Embalagem';
import { FormGtin } from './protocols/Gtin';

export interface ISelect {
  label: string | undefined;
  value: number | string | undefined;
}

export interface IFormProduto {
  flg_alcoolico: {
    value: boolean;
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
    ref?: any;
  };
  cod_tipo_item: {
    value: number;
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
    ref?: any;
  };
  flg_nao_pis_cofins: {
    value: boolean;
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
    ref?: any;
  };
  cod_produto: {
    value: string;
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
    ref?: any;
  };
  cod_gtin_principal: {
    value: string;
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
    ref?: any;
  };
  tipo_nao_pis_cofins: {
    value: { label: string | undefined; value: number | undefined };
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
    ref?: any;
  };
  des_produto: {
    value: string;
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
    ref?: any;
  };
  des_reduzida: {
    value: string;
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
    ref?: any;
  };
  cod_departamento: {
    value: { label: string | undefined; value: number | undefined };
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
    ref?: any;
  };
  cod_similar: {
    value: { label: string | undefined; value: number | undefined };
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
    ref?: any;
  };
  ipi: {
    value: { label: string | undefined; value: string | undefined };
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
    ref?: any;
  };
  ipi_tipo: {
    value: { label: string | undefined; value: string | undefined };
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
    ref?: any;
  };
  des_unidade_compra: {
    value: { label: string | undefined; value: string | undefined };
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
    ref?: any;
  };
  qtd_embalagem_compra: {
    value: number | undefined;
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
    ref?: any;
  };
  val_peso: {
    value: string | undefined;
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
    ref?: any;
  };
  des_unidade_venda: {
    value: { label: string | undefined; value: string | undefined };
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
    ref?: any;
  };
  qtd_embalagem_venda: {
    value: number | undefined;
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
    ref?: any;
  };
  val_peso_emb: {
    value: string | undefined;
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
    ref?: any;
  };
  val_peso_bruto: {
    value: string | undefined;
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
    ref?: any;
  };
  flg_ipv: {
    value: boolean;
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
    ref?: any;
  };
  flg_envia_balanca: {
    value: boolean;
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
    ref?: any;
  };
  flg_envia_pdv: {
    value: boolean;
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
    ref?: any;
  };
  tipo_multiplicacao: {
    value: { label: string | undefined; value: number | undefined };
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
    ref?: any;
  };
  flg_permite_parc: {
    value: boolean;
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
    ref?: any;
  };

  flg_rastreavel: {
    value: boolean;
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
    ref?: any;
  };
  qtd_dias_validade: {
    value: number;
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
    ref?: any;
  };
  cod_info_nutricional: {
    value: { label: string | undefined; value: number | undefined };
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
    ref?: any;
  };
  qtd_divisor_preco: {
    value: string;
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
    ref?: any;
  };
  des_unidade_medida_preco: {
    value: { label: string; value: string | undefined };
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
    ref?: any;
  };

  Gtins: {
    gtins: FormGtin[];
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
    ref?: any;
  };

  Embalagens: {
    embalagens: FormEmbalagem[];
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
    ref?: any;
  };

  Fornecedores: {
    fornecedores: IFormProdutoFornecedor[];
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
    ref?: any;
  };
}

export interface IFormProdutoLoja {
  flg_vale_gas: {
    value: boolean;
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
  };
  flg_lib_fiscal: {
    value: boolean;
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
  };
  flg_fora_linha: {
    value: boolean;
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
  };
  cod_trib_entrada: {
    value: number | undefined;
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
  };
  des_trib_entrada: {
    value: string | undefined;
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
  };
  cod_tributacao: {
    value: number | undefined;
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
  };
  des_tributacao: {
    value: string | undefined;
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
  };
  tipo_associado: {
    value: number | undefined;
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
  };
  cod_ncm: {
    value: { label: string | undefined; value: number | undefined };
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
  };

  tecla_balanca: {
    value: string;
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
  };
  qtd_etiqueta: {
    value: number;
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
  };
  num_lote_rast: {
    value: string;
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
    isInicializado: boolean;
  };
  qtd_lote_rast: {
    value: number | undefined;
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
    isInicializado: boolean;
  };
  cod_agreg_rast: {
    value: number | undefined;
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
    isInicializado: boolean;
  };
  dta_fabricacao_rast: {
    value: string | undefined;
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
    isInicializado: boolean;
  };
  cod_receita: {
    value: { label: string | undefined; value: number | undefined };
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
  };
  cod_tara: {
    value: { label: string | undefined; value: number | undefined };
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
  };
  cod_vasilhame: {
    value: number | string | undefined;
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
  };

  consulta_vasilhame: {
    value: string;
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
  };

  Associados: {
    associados: IFormAssociado[];
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
  };
}

export interface ISelectVasilhame {
  label: string;
  value: string;
  cod_produto: string;
  des_departamento: string;
  des_produto: string;
  // cod_gtin?: string | undefined;
  isInvalid: boolean;
  isRequired: boolean;
  invalidLabel: undefined;
}

export interface IProdutoVasilhame {
  cod_produto: string;
  des_produto: string;
}

export interface IFormGtin {
  cod_gtin: {
    value: string;
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
    isNew?: boolean;
  };
}

export interface IEmbalagem {
  cod_gtin: string;
  des_unidade: string;
  qtd_embalagem: number;
  val_peso_emb: string;
  val_peso_bruto: string;
  flg_principal: boolean;
  qtd_divisor_preco: string;
  des_unidade_medida_preco: string | undefined;
}

export interface IProdutoEmbalagem {
  id: string | undefined;
  cod_gtin: string | undefined;
  des_unidade: string | undefined;
  qtd_embalagem: number | undefined;
  val_peso_emb: string | undefined;
  val_peso_bruto: string | undefined;
}

export interface IFormAssociado {
  cod_associado: {
    value: string;
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
  };

  consulta: {
    value: string;
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
  };

  des_produto: {
    value: string;
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
  };
  des_departamento: {
    value: string;
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
  };

  flg_atual_vda_assoc: {
    value: boolean;
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: undefined;
  };
  per_desc_assoc: {
    value: string;
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: undefined;
  };
  per_acresc_assoc: {
    value: string;
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: undefined;
  };
}

export interface IProdutoAssociado {
  cod_produto: string;
  des_produto: string;
  cod_gtin?: string | undefined;
  des_departamento: string;
  per_desc_assoc: number;
  per_acresc_assoc: number;
  flg_atual_vda_assoc: boolean;
}

export interface IFormProdutoFornecedor {
  consulta: {
    value: string;
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: undefined;
  };
  cod_fornecedor: {
    value: string;
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: undefined;
  };
  des_fornecedor: {
    value: string;
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: undefined;
  };
  flg_principal: {
    value: boolean;
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
  };
  flg_fabricante: {
    value: boolean;
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
  };
  Unidades: {
    unidades: IFormProdFornUN[];
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
  };
}

export interface IProdutoFornecedor {
  cod_fornecedor: string;
  des_fornecedor: string;
  num_cpf_cnpj: string;
  flg_principal: boolean;
  flg_fabricante: boolean;
  des_referencia: string;
  unidades: IFormProdFornUN[];
}

export interface IFormProdFornUN {
  des_referencia: {
    value: string;
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: undefined;
  };
  des_unidade_compra: {
    value: { label: string; value: string };
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
  };
  qtd_embalagem_compra: {
    value: number;
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
  };
}

export interface IProdFornUN {
  id: string;
  des_referencia: string;
  des_unidade_compra: string;
  qtd_embalagem_compra: number;
  des_fornecedor: string;
}

export interface ISelectProdutoFornecedor {
  label: string | undefined;
  value: number | string | undefined;
  cod_fornecedor: string;
  des_fornecedor: string;
  // flg_preferencial: boolean;
  // flg_fabricante: boolean;
  // des_referencia: string;
  isInvalid: boolean;
  isRequired: boolean;
  invalidLabel: undefined;
}

export interface ISelectAssociado {
  label: string | undefined;
  value: number | string | undefined;
  cod_produto: string;
  des_departamento: string;
  des_produto: string;
  // cod_gtin?: string | undefined;
  isInvalid: boolean;
  isRequired: boolean;
  invalidLabel: undefined;
}

export interface ISelectFornecedor {
  label: string | undefined;
  value: number | string | undefined;
  cod_fornecedor: string;
  des_fornecedor: string;
}

export interface IInfoNutricional {
  cod_info_nutricional: number;
  des_info_nutricional: string;
}

export interface ITara {
  cod_tara: number;
  des_tara: string;
}
export interface ISimilar {
  id: number;
  value: string;
  cod_similar: number;
  des_similar: string;
}

export interface IMarca {
  id: number;
  value: string;
  cod_marca: number;
  des_marca: string;
}

export interface IReceita {
  cod_receita: number;
  des_receita: string;
}

export interface IFormEmbalagem {
  cod_gtin: {
    value: { label: string; value: string };
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel?: string | undefined;
  };
  des_unidade: {
    value: { label: string; value: string };
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel?: string | undefined;
  };
  qtd_embalagem: {
    value: number;
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel?: string | undefined;
  };
  val_peso_emb: {
    value: string;
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel?: string | undefined;
  };
  val_peso_bruto: {
    value: string;
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel?: string | undefined;
  };
  flg_principal: {
    value: boolean;
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel?: string | undefined;
  };
  qtd_divisor_preco: {
    value: string;
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
  };
  des_unidade_medida_preco: {
    value: { label: string; value: string | undefined };
    isInvalid: boolean;
    isRequired: boolean;
    invalidLabel: string | undefined;
  };
}

export interface ITributacao {
  cod_tributacao: number;
  des_tributacao: string;
}

export interface IDepartamento {
  cod_departamento: number;
  descricao: string;
  cod_tipo_item: number;
  des_tipo_item: string;
  cod_id_ctb: number;
}

export interface INcm {
  cod_ncm: number;
  descricao: string;
}

export interface IGtin {
  cod_gtin: string;
}

export interface IFornecedor {
  cod_fornecedor: number;
  des_fornecedor: string;
}

export const tipoNaoIncidente: ISelect[] = [
  {
    value: 0,
    label: 'Aliquota Zero',
  },
  {
    value: 1,
    label: 'Monofásico',
  },
  {
    value: 2,
    label: 'Substituição',
  },
  {
    value: 3,
    label: 'Imune',
  },
  {
    value: 4,
    label: 'Suspensão',
  },
  {
    value: 5,
    label: 'Isento',
  },
];

export const tipoPisCofinsEntrada: ISelect[] = [
  {
    value: '50',
    label:
      '50 - Operação com Direito a Crédito - Vinculada Exclusivamente a Receita Tributada no Mercado Interno',
  },
  {
    value: '51',
    label:
      '51 - Operação com Direito a Crédito - Vinculada Exclusivamente a Receita Não Tributada no Mercado Interno',
  },
  {
    value: '52',
    label:
      '52 - Operação com Direito a Crédito - Vinculada Exclusivamente a Receita de Exportação',
  },
  {
    value: '53',
    label:
      '53 - Operação com Direito a Crédito - Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno',
  },
  {
    value: '54',
    label:
      '54 - Operação com Direito a Crédito - Vinculada a Receitas Tributadas no Mercado Interno e de Exportação',
  },
  {
    value: '55',
    label:
      '55 - Operação com Direito a Crédito - Vinculada a Receitas Não-Tributadas no Mercado Interno e de Exportação',
  },
  {
    value: '56',
    label:
      '56 - Operação com Direito a Crédito - Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno, e de Exportação',
  },
  {
    value: '60',
    label:
      '60 - Crédito Presumido - Operação de Aquisição Vinculada Exclusivamente a Receita Tributada no Mercado Interno',
  },
  {
    value: '61',
    label:
      '61 - Crédito Presumido - Operação de Aquisição Vinculada Exclusivamente a Receita Não-Tributada no Mercado Interno',
  },
  {
    value: '62',
    label:
      '62 - Crédito Presumido - Operação de Aquisição Vinculada Exclusivamente a Receita de Exportação',
  },
  {
    value: '63',
    label:
      '63 - Crédito Presumido - Operação de Aquisição Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno',
  },
  {
    value: '64',
    label:
      '64 - Crédito Presumido - Operação de Aquisição Vinculada a Receitas Tributadas no Mercado Interno e de Exportação',
  },
  {
    value: '65',
    label:
      '65 - Crédito Presumido - Operação de Aquisição Vinculada a Receitas Não-Tributadas no Mercado Interno e de Exportação',
  },
  {
    value: '66',
    label:
      '66 - Crédito Presumido - Operação de Aquisição Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno, e de Exportação',
  },
  {
    value: '67',
    label: '67 - Crédito Presumido - Outras Operações',
  },
  {
    value: '70',
    label: '70 - Operação de Aquisição sem Direito a Crédito',
  },
  {
    value: '71',
    label: '71 - Operação de Aquisição com Isenção',
  },
  {
    value: '72',
    label: '72 - Operação de Aquisição com Suspensão',
  },
  {
    value: '73',
    label: '73 - Operação de Aquisição a Alíquota Zero',
  },
  {
    value: '74',
    label: '74 - Operação de Aquisição sem Incidência da Contribuição',
  },
  {
    value: '75',
    label: '75 - Operação de Aquisição por Substituição Tributária',
  },
  {
    value: '98',
    label: '98 - Outras Operações de Entrada',
  },
  {
    value: '99',
    label: '99 - Outras Operações',
  },
];

export const tipoPisCofinsSaida: ISelect[] = [
  {
    value: '01',
    label: '01 - Operação Tributável com Alíquota Básica',
  },
  {
    value: '02',
    label: '02 - Operação Tributável com Alíquota Diferenciada',
  },
  {
    value: '03',
    label:
      '03 - Operação Tributável com Alíquota por Unidade de Medida de Produto',
  },
  {
    value: '04',
    label: '04 - Operação Tributável Monofásica - Revenda a Alíquota Zero',
  },
  {
    value: '05',
    label: '05 - Operação Tributável por Substituição Tributária',
  },
  {
    value: '06',
    label: '06 - Operação Tributável a Alíquota Zero',
  },
  {
    value: '07',
    label: '07 - Operação Isenta da Contribuição',
  },
  {
    value: '08',
    label: '08 - Operação sem Incidência da Contribuição',
  },
  {
    value: '09',
    label: '09 - Operação com Suspensão da Contribuição',
  },
  {
    value: '49',
    label: '49 - Outras Operações de Saída',
  },
];

export const tipoMultiplicacao: ISelect[] = [
  {
    value: 0,
    label: 'Não',
  },
  {
    value: 1,
    label: 'Sim',
  },
  {
    value: 2,
    label: 'C/Fiscal',
  },
];

export const tipoDivisorPreco: ISelect[] = [
  {
    value: 'GR',
    label: 'GR',
  },
  {
    value: 'KG',
    label: 'KG',
  },
  {
    value: 'MT',
    label: 'MT',
  },
  {
    value: 'UN',
    label: 'UN',
  },
  {
    value: 'LT',
    label: 'LT',
  },
];

export interface IAssociado {
  cod_produto: string;
  des_produto: string;
  des_departamento: string;
  flg_atual_vda_assoc: boolean;
  per_acresc_assoc: number;
  per_desc_assoc: number;
}

export interface ISelectString {
  id: string;
  label: string;
}

export interface ITipoItem {
  cod_tipo_item: number;
  num_tipo_item: string;
  des_tipo_item: string;
}

export interface ILoja {
  des_uf: string;
  cod_loja: number;
  des_fantasia: string;
  des_cidade: string;
  cst_pis_cof_ent_aliq_zero: string;
  cst_pis_cof_ent_monof: string;
  cst_pis_cof_ent_subst: string;
  cst_pis_cof_ent_imune: string;
  cst_pis_cof_ent_susp: string;
  cst_pis_cof_entrada: string;
  cst_pis_cof_saida_aliq_zero: string;
  cst_pis_cof_saida_monof: string;
  cst_pis_cof_saida_subst: string;
  cst_pis_cof_saida_imune: string;
  cst_pis_cof_saida_susp: string;
  cst_pis_cof_saida: string;
}

export const tipoIPI: ISelect[] = [
  {
    value: -1,
    label: 'Nenhum',
  },
  {
    value: 0,
    label: 'Percentual',
  },
  {
    value: 1,
    label: 'Monetário',
  },
];

import { createRef } from 'react';

export interface FormAssociado {
  cod_associado: {
    value: string | undefined;
    isInvalid: boolean;
    isRequired: boolean;
    ref: any;
  };
  cod_produto: {
    value: string;
    isInvalid: boolean;
    isRequired: boolean;
  };

  des_produto: {
    value: string;
    isInvalid: boolean;
    isRequired: boolean;
  };
  des_departamento: {
    value: string;
    isInvalid: boolean;
    isRequired: boolean;
  };

  flg_atual_vda_assoc: {
    value: boolean;
    isInvalid: boolean;
    isRequired: boolean;
  };
  per_desc_assoc: {
    value: string;
    isInvalid: boolean;
    isRequired: boolean;
  };
  per_acresc_assoc: {
    value: string;
    isInvalid: boolean;
    isRequired: boolean;
  };
  qtd_estoque: {
    value: number;
    isInvalid: boolean;
    isRequired: boolean;
  };
}

export const formAssociadoBlank = {
  cod_associado: {
    value: undefined,
    isInvalid: true,
    isRequired: true,
    ref: createRef<any>(),
  },
  cod_produto: {
    value: '',
    isInvalid: false,
    isRequired: false,
  },
  des_departamento: {
    value: '',
    isInvalid: false,
    isRequired: false,
  },
  des_produto: {
    value: '',
    isInvalid: false,
    isRequired: false,
  },
  flg_atual_vda_assoc: {
    value: false,
    isInvalid: false,
    isRequired: false,
  },
  per_acresc_assoc: {
    value: '',
    isInvalid: false,
    isRequired: false,
  },
  per_desc_assoc: {
    value: '',
    isInvalid: false,
    isRequired: false,
  },
  qtd_estoque: {
    value: 0,
    isInvalid: false,
    isRequired: false,
  },
};

export interface Associado {
  id?: string | undefined;
  cod_produto: string | number | undefined;
  des_produto: string;
  cod_gtin?: string | undefined;
  des_departamento: string;
  qtd_estoque: number;
  per_desc_assoc: string;
  per_acresc_assoc: string;
  flg_atual_vda_assoc: boolean;
}

import { createRef } from 'react';

export interface FormProdutoFornecedor {
  cod_fornecedor: {
    value: string | undefined;
    isInvalid: boolean;
    isRequired: boolean;
    ref: any;
  };
  num_cpf_cnpj: {
    value: string;
    isInvalid: boolean;
    isRequired: boolean;
  };
  des_fornecedor: {
    value: string;
    isInvalid: boolean;
    isRequired: boolean;
  };
  flg_principal: {
    value: boolean;
    isInvalid: boolean;
    isRequired: boolean;
  };
  flg_fabricante: {
    value: boolean;
    isInvalid: boolean;
    isRequired: boolean;
  };
  Unidades: {
    unidades: FormProdutoFornecedorUN[];
    isInvalid: boolean;
    isRequired: boolean;
  };
}

export const formProdutoFornecedorBlank = {
  cod_fornecedor: {
    value: undefined,
    isInvalid: true,
    isRequired: true,
    ref: createRef<any>(),
  },
  num_cpf_cnpj: {
    value: '',
    isInvalid: true,
    isRequired: true,
  },
  des_fornecedor: {
    value: '',
    isInvalid: false,
    isRequired: false,
  },
  flg_principal: {
    value: false,
    isInvalid: false,
    isRequired: false,
  },
  flg_fabricante: {
    value: false,
    isInvalid: false,
    isRequired: false,
  },
  Unidades: {
    unidades: [],
    isInvalid: false,
    isRequired: false,
  },
};

export interface ProdutoFornecedor {
  cod_fornecedor: string | undefined;
  des_fornecedor: string;
  flg_principal: boolean;
  flg_fabricante: boolean;
  des_referencia: string;
  unidades: FormProdutoFornecedorUN[];
}

export interface FormProdutoFornecedorUN {
  id: {
    value: string;
    isInvalid: boolean;
    isRequired: boolean;
  };
  des_referencia: {
    value: string;
    isInvalid: boolean;
    isRequired: boolean;
  };
  des_unidade_compra: {
    value: { label: string; value: string };
    isInvalid: boolean;
    isRequired: boolean;
  };
  qtd_embalagem_compra: {
    value: number;
    isInvalid: boolean;
    isRequired: boolean;
  };
}

export const formProdutoFornecedorUNBlank: FormProdutoFornecedorUN = {
  id: {
    value: '',
    isRequired: false,
    isInvalid: false,
  },
  des_referencia: {
    value: '',
    isRequired: true,
    isInvalid: true,
  },
  des_unidade_compra: {
    value: { value: 'UN', label: 'UN' },
    isRequired: true,
    isInvalid: false,
  },
  qtd_embalagem_compra: {
    value: 1,
    isInvalid: false,
    isRequired: true,
  },
};

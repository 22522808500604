export interface FormGtin {
  cod_gtin: {
    value: string;
    isInvalid: boolean;
    isRequired: boolean;
    isNew?: boolean;
  };
}

export const formGtinBlank = {
  cod_gtin: {
    value: '',
    isInvalid: false,
    isRequired: false,
    invalidLabel: undefined,
  },
};

export type TransferenciaGtinProps = {
  success: boolean;
  message: string;
  data: {
    cod_produto: string;
    cod_loja: number;
    flg_carga_pdv: boolean;
    cod_gtin: string;
  }[];
  fields: unknown[];
  count: number;
  totals: unknown[];
  pagination: { prevPage: number; lastPage: number; startPage: number };
};
